import { getIdentityProvider, IdentityProvider } from './cookie';
import { FullmaktType } from './types/entities';
/**
 * Returnerer verdien satt på HN User LastLogOn
 */
export const getSistPalogget = () => {
    return window.HN?.User?.SistPalogget;
};
/**
 * Returnerer verdien satt på HN User Name
 */
export const getInnloggetFulltNavn = () => {
    return window.HN?.User?.InnloggetFulltNavn;
};
/**
 * Returnerer verdien satt på HN User FirstName
 */
export const getInnloggetFornavn = () => {
    return window.HN?.User?.InnloggetFornavn;
};
/**
 * Returnerer verdien satt på HN User RepresentertBruker
 */
export const getRepresentertBruker = () => {
    return window.HN?.User?.RepresentertBruker;
};
/**
 * Returnerer verdien satt på HN User HasRepresentation
 */
export const getHarRepresentasjon = () => {
    return window.HN?.User?.HarRepresentasjon;
};
/**
 * Returnerer verdien satt på HN User VisPersonvelger
 */
export const getVisPersonvelger = () => {
    return window.HN?.User?.VisPersonvelger;
};
/**
 * Setter verdien til HN User VisPersonvelger
 */
export const setVisPersonvelger = (visPersonvelger) => {
    window.HN = window.HN || {};
    window.HN.User = window.HN.User || {};
    window.HN.User.VisPersonvelger = visPersonvelger;
};
/**
 * Returnerer verdien satt på HN User ErRepresentasjon
 */
export const getErRepresentasjon = () => {
    return window.HN?.User?.ErRepresentasjon;
};
/**
 * Returnerer true når innnbygger representerer en annen via fullmakt
 */
export const getErRepresentasjonViaFullmakt = () => getErRepresentasjon() === true && window.HN?.User?.FullmaktEgenskaper !== null;
/**
 * Returnerer true når innnbygger representerer en annen de har foreldreansvar for
 */
export const getErRepresentasjonMedForeldreansvar = () => getErRepresentasjon() === true && window.HN?.User?.ForeldreansvarEgenskaper !== null;
/**
 * Returnerer verdien satt på HN User SamtykkekonverteringInformert
 */
export const getSamtykkekonverteringInformert = () => {
    return window.HN?.User?.SamtykkekonverteringInformert;
};
/**
 * Returnerer verdien satt på HN User ErMellom12Og16
 */
export const getErMellom12Og16 = () => {
    return window.HN?.User?.ErMellom12Og16;
};
/**
 * Returnerer verdien satt på HN User RequiredSamtykkeLevel
 */
export const getRequiredSamtykkeLevel = () => {
    return window.HN?.User?.RequiredSamtykkeLevel;
};
/**
 * Bestemmer om bruker kan navigere vekk fra siden, uten å måtte bekrefte først
 */
export const getAllowNavigation = () => {
    return window.HN?.User?.AllowNavigation ?? false;
};
/**
 * Bestemmer om bruker kan navigere vekk fra siden, uten å måtte bekrefte først
 * @param value true dersom navigasjon skal være lov, false dersom bruker må bekrefte eventuelle endringer først
 */
export const setAllowNavigation = (value) => {
    window.HN = window.HN || {};
    window.HN.User = window.HN.User || {};
    window.HN.User.AllowNavigation = value;
};
/**
 * Returnerer true når FullmaktEgenskaper er analog og ordinær
 */
export const getErOrdinaerAnalogFullmakt = () => window.HN?.User?.FullmaktEgenskaper?.FullmaktType === FullmaktType.OrdinaerFullmakt;
/**
 * Returnerer true når FullmaktEgenskaper er analog og UtenSamtykkekompenanseOver12
 */
export const getErIkkeSamtykkeKompetentFullmakt = () => !!window.HN?.User?.FullmaktEgenskaper?.Analog &&
    window.HN?.User?.FullmaktEgenskaper?.FullmaktType === FullmaktType.UtenSamtykkekompenanseOver12;
/**
 * Returnerer true når bruker er logget inn via helsenorge-app
 */
export const getErInnloggetViaHnApp = () => getIdentityProvider() === IdentityProvider.HnApp;
/**
 * Returnerer true når bruker er logget inn via helsenorge-app for ungdom
 */
export const getErInnloggetViaHnAppUngdom = () => getIdentityProvider() === IdentityProvider.HnAppUngdom;
/**
 * Hent brukerens scopes
 * @returns Liste med scopes fra HN.User.Scopes, eller tom liste
 */
export const getScopes = () => {
    return window.HN?.User?.Scopes ?? [];
};
/**
 * Sjekk om bruker har scope
 * @param scope Navn på scope i HN.User.Scopes
 * @returns true dersom bruker har scope
 */
export const hasScope = (scope) => {
    return getScopes().includes(scope) ?? false;
};
/**
 * Sjekk om bruker har innsynssperre
 * @returns true dersom bruker har innsynssperre
 */
export const getHarInnsynssperre = () => {
    return window.HN?.User?.HarInnsynssperre;
};
/**
 * Sjekk om bruker har reservasjon mot egen bruk av Helsenorge
 * @returns true dersom bruker har reservasjon mot egen bruk av Helsenorge
 */
export const getHarReservasjonAktivPaaHelsenorge = () => {
    return window.HN?.User?.HarReservasjonAktivPaaHelsenorge;
};
/**
 * Sjekk om bruker har aktivt foreldresamtykke.
 * Denne brukes når en foreldre har samtykket på vegne av barnet at de kan bruke helsenorge selv,
 * og da tilgjengeliggjøres et sett med forhåndsdefinerte tjenester.
 * @returns true dersom bruker har foreldresamtykke
 */
export const getHarAktivtForeldreSamtykke = () => {
    return window.HN?.User?.HarAktivtForeldreSamtykke;
};
/**
 * Hent innbyggers personverninnstillinger
 * @returns Liste med guids fra HN.User.AktiveUtvalgtePersonverninnstillinger, eller tom liste
 */
export const getPersonverninnstillinger = () => {
    return window.HN?.User?.AktiveUtvalgtePersonverninnstillinger ?? [];
};
/**
 * Sjekk om bruker har personverninnstilling
 * @param guid Guid i HN.User.AktiveUtvalgtePersonverninnstillinger
 * @returns true dersom bruker har personverninnstilling
 */
export const hasPersonverninnstilling = (guid) => {
    return getPersonverninnstillinger().includes(guid) ?? false;
};
