import LanguageLocales from '@helsenorge/core-utils/constants/languages';
import { getCookieValue } from './cookie';
import { setSharedCookie } from './shared-cookies';
import { CommonEvents } from './web-component/constants';
import { HNaddEventListener } from './web-component/events';
/**
 * Navn på cookie som brukes til å lagre brukerens språk
 */
const LANGUAGE_COOKIE_NAME = 'hn-language';
/**
 * Hvor lang tid cookien varer
 */
const LANGUAGE_COOKIE_EXPIRES_IN_DAYS = 1;
/**
 * Hent brukerens språk fra cookie
 * @returns Valgt språk
 */
export const getUserLanguage = () => {
    const language = getCookieValue(LANGUAGE_COOKIE_NAME);
    if (typeof language === 'string' && Object.values(LanguageLocales).includes(language)) {
        return language;
    }
};
/**
 * Lagre brukerens språk i cookie og dispatch event for bytte av språk
 * @param language Valgt språk
 */
export const setUserLanguage = (language, options) => {
    setSharedCookie(LANGUAGE_COOKIE_NAME, language, LANGUAGE_COOKIE_EXPIRES_IN_DAYS);
    document.dispatchEvent(new CustomEvent(CommonEvents.setLanguage, { detail: { language, options } }));
};
/**
 * Lytt på endringer i brukerens språk
 * @param callback Funksjon som kalles når brukeren endrer språk
 */
export const useSetUserLanguageEvent = (callback) => HNaddEventListener(CommonEvents.setLanguage, document, event => callback(event.detail.language, event.detail.options));
/**
 * Hent riktig språkkode for bruk i <html lang="xx">-atributtet
 * @param language Valgt språk
 * @returns ISO 639-1 språkkode https://en.wikipedia.org/wiki/List_of_ISO_639-1_codes
 */
export const getIsoLanguageName = (language) => {
    switch (language) {
        case LanguageLocales.NORWEGIAN:
            return 'no';
        case LanguageLocales.NORWEGIAN_NYNORSK:
            return 'nn';
        case LanguageLocales.ENGLISH:
            return 'en';
        case LanguageLocales.LITHUANIAN:
            return 'lt';
        case LanguageLocales.POLISH:
            return 'pl';
        case LanguageLocales.RUSSIAN:
            return 'ru';
        case LanguageLocales.ROMANIAN:
            return 'ro';
        case LanguageLocales.SAMI_NORTHERN:
            return 'se';
        default:
            return undefined;
    }
};
/**
 * Oppdater <html lang="xx">-atributtet med valgt språk ihht ISO 639-1-standard. Brukes på tjenester, på åpne sider settes dette av backend.
 * @param language Valgt språk
 */
export const setDocumentLanguage = (language) => {
    const isoLanguage = getIsoLanguageName(language);
    if (isoLanguage) {
        document.documentElement.lang = isoLanguage;
    }
};
