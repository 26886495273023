import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { isDebug } from '../hn-debug';
export const getResourceKey = (project, language) => JSON.stringify({ project, language });
/**
 * Returnerer ResourceItem fra resourcesState basert på project og language
 * @param resourcesState - incoming ResourcesState
 * @param project - project string
 * @param language - language string
 */
export const getResources = (resourcesState, project, language) => {
    const key = getResourceKey(project, language);
    return key in resourcesState ? resourcesState[key].items : {};
};
/**
 * Returnerer ResourceItem fra global Staten basert på project
 * @param state - incoming GlobalStateWithResources
 * @param project - project string
 */
export const getResourcesFromState = (state, project) => {
    const key = getResourceKey(project, state.language.currentLanguage);
    const value = state.resources[key];
    return value && value.status === 'succeeded' ? value.items : null;
};
/**
 * Returnerer Resource fra global Staten basert på project og language
 * Returnerer ny Resource med default status hvis den ikke finnes
 * @param state - incoming GlobalStateWithResources
 * @param project - project string
 */
export const getResourcesObjectFromState = (state, project) => {
    const key = getResourceKey(project, state.language.currentLanguage);
    return key in state.resources ? state.resources[key] : { items: {} };
};
export const getResourceWithId = (json) => {
    return Object.keys(json).reduce((previous, key) => {
        if (typeof json[key] === 'string') {
            previous[key] = json[key] + ' [' + key + ']';
        }
        return previous;
    }, {});
};
export function getModifiedResourcesForTest(resourceText) {
    if (isDebug() && window.document.URL.includes('hjelpetekst=true')) {
        return getResourceWithId(resourceText);
    }
    if (process.env.NODE_ENV === 'test') {
        return Object.keys(resourceText).reduce((previous, key) => {
            previous[key] = key;
            return previous;
        }, {});
    }
    return resourceText;
}
/**
 * Returnerer false om resources allerede ligger i staten eller er i ferd med å lastes
 * @param state - incoming GlobalStateWithResources
 * @param project - project string
 * @param language - language LanguageLocales
 */
export const shouldFetchResources = (state, project, language) => {
    const key = getResourceKey(project, language);
    const resource = state.resources[key];
    return resource?.status !== 'loading' && resource?.status !== 'succeeded';
};
/**
 * Hent nye resources basert på project name og language
 */
export const fetchResources = createAsyncThunk('resources/fetchResources', async ({ project, language, getResources }, { rejectWithValue }) => {
    try {
        const data = await getResources(project, language);
        return getModifiedResourcesForTest(data);
    }
    catch {
        return rejectWithValue(`Kunne ikke hente resources for ${project} på ${language}`);
    }
}, {
    condition: ({ project, language }, { getState }) => {
        return shouldFetchResources(getState(), project, language);
    },
});
const initialState = {};
const resourcesSlice = createSlice({
    name: 'resources',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchResources.pending, (state, action) => {
            const key = getResourceKey(action.meta.arg.project, action.meta.arg.language);
            state[key] = {
                status: 'loading',
                items: null,
            };
        })
            .addCase(fetchResources.fulfilled, (state, action) => {
            const key = getResourceKey(action.meta.arg.project, action.meta.arg.language);
            state[key] = {
                status: 'succeeded',
                items: action.payload,
            };
        })
            .addCase(fetchResources.rejected, (state, action) => {
            const key = getResourceKey(action.meta.arg.project, action.meta.arg.language);
            state[key] = {
                status: 'failed',
                items: null,
            };
        });
    },
});
export default resourcesSlice.reducer;
