import { jsx as _jsx } from "react/jsx-runtime";
import ReactDOM, { render } from 'react-dom';
import { error } from './../logger';
import { RegisterWebCompSetup } from './helpers';
export const isCustomElementRegistered = (name) => {
    return !!window.customElements.get(name);
};
export function registerWebComp(ChildComponent, name, config, templateName) {
    class WebComponent extends HTMLElement {
        constructor() {
            super();
            Object.defineProperty(this, "mountPoint", {
                enumerable: true,
                configurable: true,
                writable: true,
                value: void 0
            });
            Object.defineProperty(this, "props", {
                enumerable: true,
                configurable: true,
                writable: true,
                value: void 0
            });
            Object.defineProperty(this, "observer", {
                enumerable: true,
                configurable: true,
                writable: true,
                value: void 0
            });
            Object.defineProperty(this, "eventDispatcher", {
                enumerable: true,
                configurable: true,
                writable: true,
                value: (event) => {
                    this.dispatchEvent(event);
                }
            });
            // Listen to Events from outside
            Object.defineProperty(this, "subscribeDispatcher", {
                enumerable: true,
                configurable: true,
                writable: true,
                value: (eventname, callback) => {
                    this.addEventListener(eventname, e => {
                        return callback(e);
                    });
                }
            });
        }
        connectedCallback() {
            this.mountReactComponent();
            this.observer = new MutationObserver(mutations => {
                let shouldRerender = false;
                mutations.forEach(mutation => {
                    if (mutation.type === 'attributes' &&
                        mutation.attributeName &&
                        mutation.oldValue !== mutation.target.getAttribute(mutation.attributeName)) {
                        shouldRerender = true;
                    }
                });
                if (shouldRerender) {
                    this.renderReactComponent();
                }
            });
            this.observer.observe(this, { attributes: true });
        }
        disconnectedCallback() {
            if (this.mountPoint) {
                ReactDOM.unmountComponentAtNode(this.mountPoint);
            }
            this.observer?.disconnect();
        }
        get value() {
            return Array.from(this.attributes).reduce((p, c) => {
                p[c.name] = c.value;
                return p;
            }, {});
        }
        mountReactComponent() {
            this.mountPoint = document.createElement('div');
            if (!templateName) {
                error('Registrering av webcomp feiler: finner ikke template name');
                return;
            }
            // TEMPLATE
            // Her settes id til templatet
            const tmpl = document.getElementById(templateName);
            if (!tmpl) {
                error(`Kunne ikke finne html <template> med id: ${templateName} sjekk i index-html at <template> er oppgitt med riktig id`);
                return;
            }
            // SHADOW-DOM
            // Create an attach to the shadowDOM's root. This is necessary before querying the DOM
            const shadowRoot = this.attachShadow({ mode: 'open' });
            // TEMPLATE + SHADOW-DOM
            // Select the template content and append it to the shadowDOM
            shadowRoot.appendChild(tmpl.content.cloneNode(true));
            shadowRoot.appendChild(this.mountPoint);
            this.renderReactComponent();
        }
        renderReactComponent() {
            this.props = this.value;
            // @todo Fjern assertion etter overgang til React 18 render
            render((_jsx(RegisterWebCompSetup, { config: config, eventDispatcher: this.eventDispatcher, subscribeDispatcher: this.subscribeDispatcher, children: _jsx(ChildComponent, { ...this.props }) })), this.mountPoint);
        }
    }
    //her settes navnet til web component elementet
    if (!isCustomElementRegistered(name)) {
        window.customElements.define(name, WebComponent);
    }
}
export default registerWebComp;
