import { format } from 'date-fns';
import { trackError } from './adobe-analytics';
import { getCookieValue } from './cookie';
import { parseParams } from './hn-service';
import { warn, error as logError } from './logger';
const isProxyErrorResponse = (response) => {
    return (typeof response === 'object' &&
        response !== null &&
        response.code !== undefined &&
        response.errorCategory !== undefined &&
        response.message !== undefined);
};
const isPascalCaseProxyErrorResponse = (response) => {
    return (typeof response === 'object' &&
        response !== null &&
        response.Code !== undefined &&
        response.ErrorCategory !== undefined &&
        response.Message !== undefined);
};
const mapPascalCaseErrorToCamelCase = (response) => ({
    code: response.Code,
    errorCategory: response.ErrorCategory,
    message: response.Message,
});
const saveCorrelationId = (correlationId) => {
    window.HN = window.HN || {};
    window.HN.CorrelationIdList = window.HN.CorrelationIdList || [];
    window.HN.CorrelationIdList.push(correlationId);
};
/**
 * Hent correlationId fra API-respons
 * @param response Response fra APIet
 * @returns correlationId som guid
 */
export const getCorrelationIdFromResponse = (response) => {
    const correlationId = response.headers.get('CorrelationId');
    if (correlationId) {
        saveCorrelationId(correlationId);
    }
    return correlationId;
};
export const getCorrelationIdList = () => window.HN?.CorrelationIdList;
/**
 * Custom error i tilfelle APIet returnerer en 4XX- eller 5XX-feil, og en standard
 * Helsenorge-feilrespons med code, errorCategory og message.
 *
 * Eksempel på bruk med get():
 *
 * try {
 *   await get('koronasertifikat', 'v1/CoronaCertificate');
 * } catch (error) {
 *   if (error instanceof HelsenorgeProxyError && error.response.code === "HFU-100400") {
 *     // I tilfelle backend svarer med en spesiell feilkode
 *   }
 *   // Gjør noe annet for andre feil
 * }
 */
export class HelsenorgeProxyError extends Error {
    constructor(statusCode, message, correlationId, response, ...params) {
        // Pass remaining arguments (including vendor specific ones) to parent constructor
        super(...params);
        Object.defineProperty(this, "statusCode", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "correlationId", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "response", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.setPrototypeOf(this, new.target.prototype); // restore prototype chain
        // Maintains proper stack trace for where our error was thrown (only available on V8)
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, HelsenorgeProxyError);
        }
        this.name = 'HelsenorgeProxyError';
        // Custom debugging information
        this.statusCode = statusCode;
        this.message = message;
        this.correlationId = correlationId;
        this.response = response;
    }
}
/**
 * Custom error i tilfelle APIet returnerer en 4XX- eller 5XX-feil, men der responsen
 * ikke er i henhold til standard.
 */
export class HelsenorgeHttpError extends Error {
    constructor(statusCode, message, correlationId, response, ...params) {
        // Pass remaining arguments (including vendor specific ones) to parent constructor
        super(...params);
        Object.defineProperty(this, "statusCode", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "correlationId", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "response", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.setPrototypeOf(this, new.target.prototype); // restore prototype chain
        // Maintains proper stack trace for where our error was thrown (only available on V8)
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, HelsenorgeHttpError);
        }
        this.name = 'HelsenorgeHttpError';
        // Custom debugging information
        this.statusCode = statusCode;
        this.message = message;
        this.correlationId = correlationId;
        this.response = response;
    }
}
/**
 * Returnerer baseUrl til Tjenester basert på HN Rest objektet
 */
export const getTjenesterUrl = () => {
    return window.HN?.Rest?.TjenesterUrl ?? '';
};
/**
 * Returnerer true når window urlen er lik tjenester baseUrl
 */
export const erTjenester = () => {
    if (window && window.location) {
        return window.location.origin === getTjenesterUrl();
    }
    return false;
};
/**
 * Returnerer proxy url med MinHelse baseUrl og proxynavnet
 * @param proxyName navn på api-et/løsningsområdet. Eks pasientjournal eller velgbehandlingssted
 * @param endpoint  path for endepunktet inkludert versjon. Eks: api/v1/Behandlinger eller v1/Behandlinger
 */
export const getTjenesterApiUrl = (proxyName, endpoint) => {
    const apiUrl = window.HN?.Rest?.TjenesterApiUrl ?? '';
    return `${apiUrl}/proxy/${proxyName}/${endpoint}`;
};
export function getServerLogLevel() {
    return window.HN?.Rest?.LogLevel ?? null;
}
/**
 * Returnerer default params som trengs i en vanlig request
 * @returns Objekt med headers
 */
const getRequestHeaders = () => {
    const headers = {};
    if (window.HN?.Rest?.HendelseLoggType) {
        headers['X-hn-hendelselogg'] = window.HN.Rest.HendelseLoggType;
    }
    if (getCookieValue('HN_CSRF_Token')) {
        headers['X-HN-CSRF-Token'] = getCookieValue('HN_CSRF_Token');
    }
    return headers;
};
/**
 * Returnerer true når window urlen er lik Helsenorge baseUrl
 */
export const erHelsenorge = () => {
    return window.location.origin === getHelsenorgeUrl();
};
/**
 * Returnerer Headers som trengs i en vanlig request
 * @param type optional content-type - default er json
 */
export const createHeaders = (type = 'application/json') => {
    const headers = new Headers(getRequestHeaders());
    headers.append('Accept', type);
    headers.append('Content-Type', type);
    return headers;
};
/**
 * Returnerer filnavn fra et Response-objekt ved nedlasting av filer
 * @param response Responsen fra et fetch-kall
 * @returns filnavn fra Content-Disposition-header, eller en fallback dersom headeren ikke finnes
 */
export const getFilenameFromResponse = (response) => {
    let filename = 'nedlasting-' + format(new Date(), 'yyyy-M-d') + '-helseNorge';
    const contentDisposition = response.headers.get('content-disposition');
    if (contentDisposition) {
        const utf8Regex = /filename\*=utf-8''(.+)/i;
        const asciiRegex = /filename="(.+)"/i;
        const asciiRegexWithoutQuotes = /filename=(.+)/i;
        const match = contentDisposition.match(utf8Regex) ??
            contentDisposition.match(asciiRegex) ??
            contentDisposition.match(asciiRegexWithoutQuotes) ??
            false;
        if (match) {
            try {
                filename = decodeURI(match[1]);
            }
            catch (e) {
                logError(`Responsen fra ${response.url} inneholder ugyldige tegn i filnavnet i Content-Disposition-headeren: ${contentDisposition}`);
            }
        }
    }
    return filename;
};
/**
 * Returnerer JSON error objekt - f.eks '{ "Code":"EHAPI-100000", "Message":"Teknisk feil", "ErrorCategory": 0}'
 * @param html ErrorMsg i html
 */
export const getErrorFromHTML = (html) => {
    const first = html.indexOf('{');
    const last = html.lastIndexOf('}');
    const everything = html.substring(first, last + 1);
    if (everything === '') {
        return null;
    }
    return JSON.parse(everything);
};
/**
 * Returnerer url basert på HN Rest objektet
 */
export function getHelsenorgeUrl() {
    return window.HN?.Rest?.HelseNorgeUrl ?? '';
}
/**
 * Returnerer full query string basert på parametrene sendt som argument '?param1=value&param2=value'
 * @param params opjekt med parametrene
 */
const createQueryString = (params) => {
    return ('?' +
        Object.keys(params)
            .map((key) => {
            return Array.isArray(params[key])
                ? params[key].map((x) => `${key}=${x}`).join('&')
                : `${key}=${params[key]}`;
        })
            .join('&'));
};
/**
 * Sjekker status på en Response og returnerer en Promise avhengig av status
 * @param response Response objektet fra HTTP request
 * @throws {HelsenorgeProxyError} Dersom APIet svarer med en feil etter Helsenorge-standard
 * @throws {HelsenorgeHttpError} Dersom APIet svarer med en feil som ikke er i standard-format
 * @throws {Error} Dersom det skjer andre feil
 */
export const checkStatus = (response) => {
    const contentType = response.headers.get('content-type');
    const correlationId = getCorrelationIdFromResponse(response);
    if (contentType?.indexOf('application/json') !== -1) {
        if (response.status === 204) {
            return Promise.resolve(null);
        }
        if (response.status === 429) {
            throw new HelsenorgeHttpError(429, 'Denne tjenesten er ikke tilgjengelig for øyeblikket. Det er for mange som ønsker å bruke tjenesten samtidig.', correlationId);
        }
        if (response.ok) {
            return response.json();
        }
        return response.json().then(error => {
            if (isProxyErrorResponse(error) &&
                (error.code === 'SEC-110000' || error.code === 'EHSEC-110000') &&
                document.location?.href.indexOf('autosignout=1') === -1) {
                // redirect dersom token er utgått eller ugyldig
                window.location.href = `${getTjenesterUrl()}/auth/autosignout`;
            }
            if (isProxyErrorResponse(error)) {
                throw new HelsenorgeProxyError(response.status, error.message, correlationId, error);
            }
            if (isPascalCaseProxyErrorResponse(error)) {
                throw new HelsenorgeProxyError(response.status, error.Message, correlationId, mapPascalCaseErrorToCamelCase(error));
            }
            throw new HelsenorgeHttpError(response.status, 'Det har skjedd en teknisk feil. Prøv igjen senere.', correlationId, error);
        });
    }
    throw new Error('Det har skjedd en teknisk feil. Prøv igjen senere.');
};
const handleError = (error, apiUrl) => {
    trackError('level1', 'Feil ved henting av data fra proxy');
    if (error instanceof TypeError && error.message === 'Failed to fetch') {
        warn(`Kall til følgende URL feilet: ${apiUrl}. Mottok ingen respons fra tjenesten.`);
        throw new Error('Det har skjedd en teknisk feil. Prøv igjen senere.');
    }
    throw error;
};
/**
 * baseCrud som brukes for å fetche. Logger eventuelle nettverksfeil med warn().
 * @param method
 * @param proxyName navn på api-et/løsningsområdet. Eks pasientjournal eller velgbehandlingssted
 * @param endpoint  path for endepunktet inkludert versjon. Eks: api/v1/Behandlinger eller v1/Behandlinger
 * @param params parametere som sendes med som query string
 * @param data
 * @throws {Error} Dersom det skjedde en feil under fetch-kallet
 */
const baseCrud = (method, proxyName, endpoint, params, data) => {
    const queryString = params && Object.keys(params).length > 0 ? createQueryString(params) : '';
    const baseUrl = getTjenesterApiUrl(proxyName, endpoint);
    const requestBody = data ? { body: JSON.stringify(data) } : {};
    const apiUrl = baseUrl + queryString;
    return fetch(apiUrl, {
        ...requestBody,
        method,
        credentials: 'include',
        headers: createHeaders(),
    })
        .then(response => checkStatus(response))
        .catch(error => handleError(error, apiUrl));
};
/**
 * get method
 * @param proxyName navn på api-et/løsningsområdet. Eks pasientjournal eller velgbehandlingssted
 * @param endpoint  path for endepunktet inkludert versjon. Eks: api/v1/Behandlinger eller v1/Behandlinger
 * @param params parametere som sendes med som query string
 */
export const get = (proxyName, endPoint, params) => {
    return baseCrud('get', proxyName, endPoint, params);
};
/**
 * Hjelpemetode for å sende get-requester til proxy-API sammen med createApi fra RTK
 * @param args
 * @returns
 */
export const getBaseQuery = async (args) => {
    try {
        const response = await get(...args);
        return { data: response };
    }
    catch (error) {
        return { error: error instanceof Error ? error.message : 'Teknisk feil' };
    }
};
/**
 * post method
 * @param proxyName navn på api-et/løsningsområdet. Eks pasientjournal eller velgbehandlingssted
 * @param endpoint  path for endepunktet inkludert versjon. Eks: api/v1/Behandlinger eller v1/Behandlinger
 * @param params parametere som sendes med som query string
 * @param data
 */
export const post = (proxyName, endPoint, data, params) => {
    return baseCrud('post', proxyName, endPoint, params, data);
};
/**
 * put method
 * @param proxyName navn på api-et/løsningsområdet. Eks pasientjournal eller velgbehandlingssted
 * @param endpoint  path for endepunktet inkludert versjon. Eks: api/v1/Behandlinger eller v1/Behandlinger
 * @param params parametere som sendes med som query string
 * @param data
 */
export const put = (proxyName, endpoint, data, params) => {
    return baseCrud('put', proxyName, endpoint, params, data);
};
/**
 * patch method
 * @param proxyName navn på api-et/løsningsområdet. Eks pasientjournal eller velgbehandlingssted
 * @param endpoint  path for endepunktet inkludert versjon. Eks: api/v1/Behandlinger eller v1/Behandlinger
 * @param params parametere som sendes med som query string
 * @param data
 */
export const patch = (proxyName, endpoint, data, params) => {
    return baseCrud('patch', proxyName, endpoint, params, data);
};
/**
 * remove method
 * @param proxyName navn på api-et/løsningsområdet. Eks pasientjournal eller velgbehandlingssted
 * @param endpoint  path for endepunktet inkludert versjon. Eks: api/v1/Behandlinger eller v1/Behandlinger
 * @param params parametere som sendes med som query string
 * @param data
 */
export const remove = (proxyName, endpoint, data, params) => {
    return baseCrud('delete', proxyName, endpoint, params, data);
};
/**
 * Konkatenerer url-lenke basert på environment, proxy, url, request params og custom params
 * @param proxyName navn på api-et/løsningsområdet. Eks pasientjournal eller velgbehandlingssted
 * @param endpoint  path for endepunktet inkludert versjon. Eks: api/v1/Behandlinger eller v1/Behandlinger
 * @param params parametere som sendes med som query string
 */
export const link = (proxyName, endpoint, params) => {
    return getTjenesterApiUrl(proxyName, endpoint) + (params ? createQueryString(params) : '');
};
/**
 * Las ned en fil/Blob i nettleseren
 * @param download Info om fil som skal lastes ned
 */
const downloadFileInBrowser = async (download) => {
    const blob = await download.blob;
    const a = document.createElement('a');
    document.body.appendChild(a);
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = download.fileName;
    a.click();
    window.URL.revokeObjectURL(url);
};
/**
 * Sjekker status på en Response og returnerer info om fil som skal nedlastes
 * @param response Response objektet fra HTTP request
 * @throws {HelsenorgeProxyError} Dersom APIet svarer med en feil etter Helsenorge-standard
 * @throws {HelsenorgeHttpError} Dersom APIet svarer med en feil som ikke er i standard-format
 */
const handleDownloadResponse = async (response) => {
    if (!response.ok) {
        const error = await response.text();
        const correlationId = getCorrelationIdFromResponse(response);
        if (error === '401') {
            window.location.reload();
            throw new HelsenorgeHttpError(401, 'Det har skjedd en teknisk feil. Prøv igjen senere.', correlationId, error);
        }
        else {
            logError('responseHtml', error);
            const errorResponse = getErrorFromHTML(error);
            if (isProxyErrorResponse(errorResponse)) {
                throw new HelsenorgeProxyError(response.status, errorResponse.message, correlationId, errorResponse);
            }
            else if (isPascalCaseProxyErrorResponse(errorResponse)) {
                throw new HelsenorgeProxyError(response.status, errorResponse.Message, correlationId, mapPascalCaseErrorToCamelCase(errorResponse));
            }
            throw new HelsenorgeHttpError(response.status, 'Det har skjedd en teknisk feil. Prøv igjen senere.', correlationId, error);
        }
    }
    const fileName = getFilenameFromResponse(response);
    const blob = response.blob();
    return { blob, fileName };
};
/**
 * Fetch for nedlasting av filer
 * @param proxyName navn på api-et/løsningsområdet. Eks pasientjournal eller velgbehandlingssted
 * @param endpoint  path for endepunktet inkludert versjon. Eks: api/v1/Behandlinger eller v1/Behandlinger
 * @param params parametere som sendes med som query string
 * @param method HTTP-metode: get eller post
 * @param data Data som som sendes som body med fetch
 */
export const download = (proxyName, endpoint, params, method = 'get', data) => {
    const queryString = method === 'get' ? parseParams(params) : '';
    const baseUrl = getTjenesterApiUrl(proxyName, endpoint);
    const requestBody = data ? { body: JSON.stringify(data) } : {};
    const apiUrl = baseUrl + queryString;
    const headers = createHeaders();
    if (method === 'get') {
        headers.set('Content-Type', 'multipart/form-data');
    }
    return new Promise((resolve, reject) => {
        fetch(apiUrl, {
            ...requestBody,
            method,
            credentials: 'include',
            headers,
        })
            .then(handleDownloadResponse)
            .then(downloadFileInBrowser)
            .then(() => resolve())
            .catch(error => {
            trackError('level1', 'Feil ved nedlasting av fil');
            if (error instanceof TypeError && error.message === 'Failed to fetch') {
                reject(new Error('Det har skjedd en teknisk feil. Prøv igjen senere.'));
            }
            reject(error);
        });
    });
};
/**
 * Last opp fil
 * @param proxyName navn på api-et/løsningsområdet. Eks pasientjournal eller velgbehandlingssted
 * @param endpoint  path for endepunktet inkludert versjon. Eks: api/v1/Behandlinger eller v1/Behandlinger
 * @param formData Fil som skal lastes opp
 * @param params parametere som sendes med som query string
 * @throws {Error} Dersom det skjedde en feil under fetch-kallet
 */
export const upload = (proxyName, endpoint, formData, params) => {
    const baseUrl = getTjenesterApiUrl(proxyName, endpoint);
    const queryString = params && Object.keys(params).length > 0 ? createQueryString(params) : '';
    const apiUrl = baseUrl + queryString;
    return fetch(apiUrl, {
        body: formData,
        method: 'POST',
        credentials: 'include',
        headers: new Headers(getRequestHeaders()),
    })
        .then(response => checkStatus(response))
        .catch(error => handleError(error, apiUrl));
};
/**
 * Hent XML fra proxy
 * @param proxyName navn på api-et/løsningsområdet. Eks pasientjournal eller velgbehandlingssted
 * @param endpoint  path for endepunktet inkludert versjon. Eks: api/v1/Behandlinger eller v1/Behandlinger
 * @param params parametere som sendes med som query string
 * @returns XML som string
 */
export const getXml = (proxyName, endpoint, params) => {
    const baseUrl = getTjenesterApiUrl(proxyName, endpoint);
    const apiUrl = baseUrl + parseParams(params);
    const headers = createHeaders('application/xml');
    const checkStatus = (response) => {
        const contentType = response.headers.get('content-type');
        if (contentType?.indexOf('application/xml') !== -1 && response.ok) {
            return response.text();
        }
        return response.text().then(error => {
            const correlationId = getCorrelationIdFromResponse(response);
            throw new HelsenorgeHttpError(response.status, 'Teknisk feil', correlationId, error);
        });
    };
    return fetch(apiUrl, {
        credentials: 'include',
        headers,
    })
        .then(checkStatus)
        .catch(error => handleError(error, apiUrl));
};
