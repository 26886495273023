/* FULLMAKT OG SAMTYKKE */
export var SamtykkeType;
(function (SamtykkeType) {
    SamtykkeType[SamtykkeType["None"] = 0] = "None";
    SamtykkeType[SamtykkeType["Pha"] = 1] = "Pha";
    SamtykkeType[SamtykkeType["Bruksvilkar"] = 2] = "Bruksvilkar";
    SamtykkeType[SamtykkeType["RefusjonPasientreiser"] = 3] = "RefusjonPasientreiser";
    SamtykkeType[SamtykkeType["UngdomsLosning"] = 4] = "UngdomsLosning";
})(SamtykkeType || (SamtykkeType = {}));
export var SamtykkeLevel;
(function (SamtykkeLevel) {
    SamtykkeLevel[SamtykkeLevel["None"] = 0] = "None";
    SamtykkeLevel[SamtykkeLevel["Registerinnsyn"] = 1] = "Registerinnsyn";
    SamtykkeLevel[SamtykkeLevel["Journalinnsyn"] = 2] = "Journalinnsyn";
    SamtykkeLevel[SamtykkeLevel["Helsetjeneste"] = 3] = "Helsetjeneste";
})(SamtykkeLevel || (SamtykkeLevel = {}));
export var FullmaktType;
(function (FullmaktType) {
    FullmaktType[FullmaktType["OrdinaerFullmakt"] = 3] = "OrdinaerFullmakt";
    FullmaktType[FullmaktType["BekreftetFullmakt"] = 4] = "BekreftetFullmakt";
    FullmaktType[FullmaktType["UtenSamtykkekompenanseOver12"] = 7] = "UtenSamtykkekompenanseOver12";
})(FullmaktType || (FullmaktType = {}));
export var SamtykkeStatusType;
(function (SamtykkeStatusType) {
    SamtykkeStatusType[SamtykkeStatusType["Samtykket"] = 0] = "Samtykket";
    SamtykkeStatusType[SamtykkeStatusType["Trukket"] = 1] = "Trukket";
    SamtykkeStatusType[SamtykkeStatusType["Utsatt"] = 2] = "Utsatt";
})(SamtykkeStatusType || (SamtykkeStatusType = {}));
/**
 * Representasjoner, samtykke og personverninnstillinger
 */
export var RepresentasjonforholdType;
(function (RepresentasjonforholdType) {
    RepresentasjonforholdType[RepresentasjonforholdType["InnloggetBruker"] = 0] = "InnloggetBruker";
    RepresentasjonforholdType[RepresentasjonforholdType["Fullmakt"] = 1] = "Fullmakt";
    RepresentasjonforholdType[RepresentasjonforholdType["Foreldrerepresentasjon"] = 2] = "Foreldrerepresentasjon";
    RepresentasjonforholdType[RepresentasjonforholdType["Saksbehandler"] = 3] = "Saksbehandler";
    RepresentasjonforholdType[RepresentasjonforholdType["SaksbehandlerFullmakt"] = 4] = "SaksbehandlerFullmakt";
})(RepresentasjonforholdType || (RepresentasjonforholdType = {}));
export var StatusKodeType;
(function (StatusKodeType) {
    StatusKodeType[StatusKodeType["Reservert"] = 1] = "Reservert";
    StatusKodeType[StatusKodeType["IkkeReservert"] = 2] = "IkkeReservert";
    StatusKodeType[StatusKodeType["Samtykket"] = 3] = "Samtykket";
    StatusKodeType[StatusKodeType["IkkeSamtykket"] = 4] = "IkkeSamtykket";
    StatusKodeType[StatusKodeType["TilgangsbegrensningOpprettet"] = 5] = "TilgangsbegrensningOpprettet";
    StatusKodeType[StatusKodeType["TilgangsbegrensningFjernet"] = 6] = "TilgangsbegrensningFjernet";
    StatusKodeType[StatusKodeType["AnmodningTilbakeTrekkingSamtykke"] = 7] = "AnmodningTilbakeTrekkingSamtykke";
})(StatusKodeType || (StatusKodeType = {}));
export var SessionTimeoutAction;
(function (SessionTimeoutAction) {
    SessionTimeoutAction[SessionTimeoutAction["Default"] = 0] = "Default";
    SessionTimeoutAction[SessionTimeoutAction["RequestExtension"] = 1] = "RequestExtension";
    SessionTimeoutAction[SessionTimeoutAction["RequestLogout"] = 2] = "RequestLogout";
})(SessionTimeoutAction || (SessionTimeoutAction = {}));
export var PersonverninnstillingKategori;
(function (PersonverninnstillingKategori) {
    PersonverninnstillingKategori[PersonverninnstillingKategori["Samtykke"] = 1] = "Samtykke";
    PersonverninnstillingKategori[PersonverninnstillingKategori["Reservasjon"] = 2] = "Reservasjon";
    PersonverninnstillingKategori[PersonverninnstillingKategori["Tilgangsbegrensning"] = 3] = "Tilgangsbegrensning";
})(PersonverninnstillingKategori || (PersonverninnstillingKategori = {}));
export var TjenesteTilgangStatus;
(function (TjenesteTilgangStatus) {
    TjenesteTilgangStatus[TjenesteTilgangStatus["None"] = 0] = "None";
    TjenesteTilgangStatus[TjenesteTilgangStatus["Ja"] = 1] = "Ja";
    TjenesteTilgangStatus[TjenesteTilgangStatus["Nei"] = 2] = "Nei";
    TjenesteTilgangStatus[TjenesteTilgangStatus["VetIkke"] = 3] = "VetIkke";
})(TjenesteTilgangStatus || (TjenesteTilgangStatus = {}));
export var Personverninnstilling;
(function (Personverninnstilling) {
    Personverninnstilling["SamtykkeDigitalHelsetjeneste"] = "fb640456-ae6f-4664-92c8-c6739d76caec";
    Personverninnstilling["SamtykkeJournalinnsyn"] = "dc5b5d14-deed-48e5-bf6d-99ed77f08a09";
    Personverninnstilling["SamtykkeRegisterinnsyn"] = "6ea1aa04-fad7-48c8-8432-1acb3fbf6d7b";
})(Personverninnstilling || (Personverninnstilling = {}));
export class TjenesteType {
}
Object.defineProperty(TjenesteType, "TekniskTjeneste", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: -1
});
Object.defineProperty(TjenesteType, "IkkeInnloggetTjeneste", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: 0
});
Object.defineProperty(TjenesteType, "Timeavtaler", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: 1
});
Object.defineProperty(TjenesteType, "Fastlegetjenester", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: 6
});
Object.defineProperty(TjenesteType, "Legemidler", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: 7
});
Object.defineProperty(TjenesteType, "PersonligHelsearkiv", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: 8
});
Object.defineProperty(TjenesteType, "Fastlegevisning", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: 9
});
Object.defineProperty(TjenesteType, "InnsynPasientjournal", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: 10
});
Object.defineProperty(TjenesteType, "InnsynKjernejournal", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: 11
});
Object.defineProperty(TjenesteType, "Egenandeler", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: 12
});
Object.defineProperty(TjenesteType, "Profil", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: 13
});
Object.defineProperty(TjenesteType, "MinHelseForside", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: 15
});
Object.defineProperty(TjenesteType, "EksterneTjenester", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: 16
});
Object.defineProperty(TjenesteType, "Henvisninger", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: 17
});
Object.defineProperty(TjenesteType, "Pasientreiser", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: 18
});
Object.defineProperty(TjenesteType, "Forlopskoordinator", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: 19
});
Object.defineProperty(TjenesteType, "Meldinger", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: 20
});
Object.defineProperty(TjenesteType, "ByttFastlege", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: 21
});
Object.defineProperty(TjenesteType, "DialogForlop", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: 22
});
Object.defineProperty(TjenesteType, "DialogHelsehjelp", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: 23
});
Object.defineProperty(TjenesteType, "Fullmaktsadministrasjon", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: 24
});
Object.defineProperty(TjenesteType, "Helsekontakter", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: 25
});
Object.defineProperty(TjenesteType, "DialogOkonomi", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: 26
});
Object.defineProperty(TjenesteType, "Beslutningsstotte", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: 27
});
Object.defineProperty(TjenesteType, "DialogInnsyn", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: 28
});
Object.defineProperty(TjenesteType, "DialogPersonvern", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: 29
});
Object.defineProperty(TjenesteType, "Helseregistre", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: 30
});
Object.defineProperty(TjenesteType, "LoggOverBrukAvResepter", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: 31
});
Object.defineProperty(TjenesteType, "SykdomOgKritiskInformasjon", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: 32
});
Object.defineProperty(TjenesteType, "Forskning", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: 33
});
Object.defineProperty(TjenesteType, "MinHelse", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: 34
});
Object.defineProperty(TjenesteType, "Timeadministrasjon", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: 35
});
Object.defineProperty(TjenesteType, "Foreldresamtykke", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: 36
});
Object.defineProperty(TjenesteType, "DialogEgenHelseUngdom", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: 37
});
Object.defineProperty(TjenesteType, "KprTjenester", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: 38
});
Object.defineProperty(TjenesteType, "Vaksiner", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: 39
});
Object.defineProperty(TjenesteType, "LegemidlerLiB", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: 40
});
Object.defineProperty(TjenesteType, "InnsynRegisterinnhold", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: 41
});
Object.defineProperty(TjenesteType, "InnsynRegisterbruk", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: 42
});
Object.defineProperty(TjenesteType, "EKonsultasjon", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: 43
});
Object.defineProperty(TjenesteType, "Reseptfornyelse", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: 44
});
Object.defineProperty(TjenesteType, "EKontakt", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: 45
});
Object.defineProperty(TjenesteType, "Timebestilling", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: 46
});
Object.defineProperty(TjenesteType, "KritiskInfo", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: 47
});
Object.defineProperty(TjenesteType, "AapneKjernejournal", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: 48
});
Object.defineProperty(TjenesteType, "ProfildataOgKjernejournal", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: 49
});
Object.defineProperty(TjenesteType, "HelsekontakterIKjernejournal", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: 50
});
Object.defineProperty(TjenesteType, "LegemidlerFraKjernejournal", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: 51
});
Object.defineProperty(TjenesteType, "ProfildataOgKjernejournalReservasjon", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: 52
});
Object.defineProperty(TjenesteType, "PersonopplysningerFraKjernejournal", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: 53
});
Object.defineProperty(TjenesteType, "Donorkort", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: 54
});
Object.defineProperty(TjenesteType, "Samtykkeforesporsel", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: 55
});
Object.defineProperty(TjenesteType, "DialogForlopInformasjon", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: 56
});
Object.defineProperty(TjenesteType, "Pasientreiseradministrasjon", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: 57
});
Object.defineProperty(TjenesteType, "NyttHelsenorgeSamtykke", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: 58
});
Object.defineProperty(TjenesteType, "InngripendeForPersonvern", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: 59
});
Object.defineProperty(TjenesteType, "Skjemautfyller", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: 60
});
Object.defineProperty(TjenesteType, "SamvalgUtenLagring", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: 61
});
Object.defineProperty(TjenesteType, "MeldeFeilIBesokshistorikkKj", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: 62
});
Object.defineProperty(TjenesteType, "Verktoy", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: 63
});
Object.defineProperty(TjenesteType, "VerktoyUtforelse", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: 64
});
Object.defineProperty(TjenesteType, "InnsynRegisterinnholdReseptformidler", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: 65
});
Object.defineProperty(TjenesteType, "DialogSkjemaRegisterinnsyn", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: 66
});
Object.defineProperty(TjenesteType, "DialogSkjemaJournalinnsyn", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: 67
});
Object.defineProperty(TjenesteType, "DialogSkjemaHelsetjeneste", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: 68
});
Object.defineProperty(TjenesteType, "DialogSkjemaHelsetjenesteStreng", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: 69
});
Object.defineProperty(TjenesteType, "RegisterinnsynNasjonaleKvalitetsregister", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: 70
});
Object.defineProperty(TjenesteType, "ReiserMedRekvisisjon", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: 71
});
/* CMS-CONTENT-API-SERVICE */
export var CmsContentApiVersions;
(function (CmsContentApiVersions) {
    CmsContentApiVersions["V1"] = "v1/";
    CmsContentApiVersions["V2"] = "v2/";
})(CmsContentApiVersions || (CmsContentApiVersions = {}));
