import { trackError } from './adobe-analytics';
import { getUserLanguage } from './hn-language';
import { getHelsenorgeUrl } from './hn-proxy-service';
import { checkStatus, createBaseHeaders, parseParams } from './hn-service';
import { warn } from './logger';
import { CmsContentApiVersions } from './types/entities';
/**
 * Returnerer CmsContentApiUrl fra window.HN.Rest
 */
export const getContentApiUrl = () => {
    return !!window.HN && !!window.HN.Rest && !!window.HN.Rest.CmsContentApiUrl ? window.HN.Rest.CmsContentApiUrl : '';
};
/**
 * Returnerer CmsContentApiPreviewUrl fra window.HN.Rest
 */
export const getContentApiPreviewUrl = () => {
    return !!window.HN && !!window.HN.Rest && !!window.HN.Rest.CmsContentApiPreviewUrl ? window.HN.Rest.CmsContentApiPreviewUrl : '';
};
/**
 * Returnerer true dersom URLen man står på inneholder content-api-preview=true
 */
export const enableContentApiPreview = () => {
    return !!window && window.location.href.indexOf('content-api-preview=true') !== -1;
};
const handleError = (error, apiUrl) => {
    trackError('level1', 'Feil ved henting av data fra CMS');
    if (error instanceof TypeError && error.message === 'Failed to fetch') {
        warn(`Kall til content-apiet feilet: ${apiUrl}. Mottok ingen respons fra tjenesten.`);
    }
    else if (error instanceof Error) {
        warn(`Kall til content-apiet feilet: ${apiUrl}`, error);
    }
    throw error;
};
/**
 * Henter JSON fra content-apiet med fetch(). Returnerer et Promise.
 * Logger eventuelle feil med warn().
 * @param endpoint command strengen som sendes mot content-apiet
 * @param params object med parameters { param1 : 'myparam1', param2: 'myparam2'}
 * @param version setter hvilken versjon av conetnapi som skal kalles (v1/, v2/)
 * @throws {Error} Dersom det skjedde en feil under henting av data fra content-apiet.
 */
export const get = (endpoint, params = {}, version = CmsContentApiVersions.V1) => {
    const preview = enableContentApiPreview();
    const hostName = preview ? getContentApiPreviewUrl() : getContentApiUrl();
    const credentials = preview ? 'include' : 'omit'; // Må settes til omit for å kunne bruke wildcard for domener i CORS
    const headers = createBaseHeaders();
    if (preview) {
        headers.append('X-Preview', 'true');
    }
    const { languageCode = getUserLanguage(), ...restParams } = params;
    const apiUrl = `${hostName}/contentapi/internal/${version}${endpoint}${parseParams({ languageCode, ...restParams })}`;
    return fetch(apiUrl, {
        method: 'get',
        credentials,
        headers: headers,
    })
        .then(response => checkStatus(response))
        .catch(error => handleError(error, apiUrl));
};
/**
 * Hjelpemetode for å sende get-requester til content-API sammen med createApi fra RTK
 * @param args
 * @returns
 */
export const getBaseQuery = async (args) => {
    try {
        const response = await get(...args);
        return { data: response };
    }
    catch (error) {
        return { error: error instanceof Error ? error.message : 'Teknisk feil' };
    }
};
/**
 * Send JSON til CMS-apiet med fetch() som POST-request. Returnerer et Promise.
 * Logger eventuelle feil med warn().
 * @param endpoint command strengen som sendes mot apiet
 * @param data object med data { param1 : 'myparam1', param2: 'myparam2'}
 * @param params object med parameters { param1 : 'myparam1', param2: 'myparam2'}
 * @throws {Error} Dersom det skjedde en feil under henting av data fra content-apiet.
 */
export const post = (endpoint, data, params = {}) => {
    const hostName = getContentApiUrl();
    const headers = createBaseHeaders();
    const apiUrl = `${hostName}/api/${endpoint}${parseParams(params)}`;
    return fetch(apiUrl, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(data),
    })
        .then(response => checkStatus(response))
        .catch(error => handleError(error, apiUrl));
};
/**
 * Send JSON til CMS-apiet med fetch() som PATCH-request. Returnerer et Promise.
 * Logger eventuelle feil med warn().
 * @param endpoint command strengen som sendes mot apiet
 * @param data object med data { param1 : 'myparam1', param2: 'myparam2'}
 * @param params object med parameters { param1 : 'myparam1', param2: 'myparam2'}
 * @throws {Error} Dersom det skjedde en feil under henting av data fra content-apiet.
 */
export const patch = (endpoint, data, params = {}) => {
    const hostName = getContentApiUrl();
    const headers = createBaseHeaders();
    const apiUrl = `${hostName}/api/${endpoint}${parseParams(params)}`;
    return fetch(apiUrl, {
        method: 'PATCH',
        headers: headers,
        body: JSON.stringify(data),
    })
        .then(response => checkStatus(response))
        .catch(error => handleError(error, apiUrl));
};
/**
 * Henter JSON fra Helsenorge-proxy (samme domene som content-apiet) med fetch().
 * Skal kun benyttes for åpne api-kall til tjenester der det er satt opp proxy (SOT).
 * Returnerer et Promise.
 * Logger eventuelle feil med warn().
 * @param proxyName navn på api-et/løsningsområdet. Eks pasientjournal eller velgbehandlingssted
 * @param endpoint  path for endepunktet inkludert versjon. Eks: api/v1/Behandlinger eller v1/Behandlinger
 * @param params object med parameters { param1 : 'myparam1', param2: 'myparam2'}
 * @throws {Error} Dersom det skjedde en feil under henting av data fra content-apiet.
 */
export const getHelsenorgeProxy = (proxyName, endpoint, params) => {
    const apiUrl = getHelsenorgeUrl() + '/proxy/' + proxyName + '/' + endpoint + parseParams(params);
    return fetch(apiUrl, {
        method: 'get',
        credentials: 'omit', // Må settes til omit for å kunne bruke wildcard for domener i CORS
        headers: createBaseHeaders(),
    })
        .then(response => checkStatus(response))
        .catch(error => {
        if (error == 'TypeError: Failed to fetch') {
            warn(`Kall til helsenorge proxy: ${apiUrl}. Mottok ingen respons fra tjenesten.`);
        }
        else {
            warn(`Kall til helsenorge proxy feilet: ${apiUrl}. Feilmelding: ${JSON.stringify(error)}`);
        }
        throw error;
    });
};
