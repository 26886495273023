/** Cookie-navn for om man er logget inn */
export const LOGGED_IN_COOKIE_NAME = 'MH_LoggedIn';
/** Cookie-navn for sesjons-ID */
export const SESSION_ID_COOKIE_NAME = 'MH_SessionId';
/** Cookie-navn for måten man har logget inn på */
export const IDENTITY_PROVIDER_COOKIE_NAME = 'MH_Idp';
/** Cookie-navn for versjon av appen */
export const APP_VERSION_COOKIE_NAME = 'MH_App_Version';
/**
 * Sjekker om cookie finnes og om den har verdi
 * @param cookieName navn på cookie
 * @param value cookie verdi
 */
export const hasCookie = (cookieName, value) => {
    if (typeof document === 'undefined') {
        return false;
    }
    const cookie = document.cookie;
    if (cookie === undefined) {
        return false;
    }
    if (value === undefined) {
        return cookie.split('; ').find(e => e.split('=')[0] === cookieName) !== undefined;
    }
    const cookieValue = '; '.concat(document.cookie);
    const parts = cookieValue.split('; '.concat(cookieName).concat('='));
    if (parts.length === 2) {
        const lastPart = parts.pop();
        return lastPart ? lastPart.split(';').shift() === value.toString() : false;
    }
    return false;
};
/**
 * Henter cookienavn inkludert eventuelt suffix for å støtte ulike domener.
 * @param cookieName navn på cookie
 */
export const getSuffix = (cookieName) => {
    let suffix = '';
    if (window.HN?.Rest?.Environment) {
        suffix = '_' + window.HN.Rest.Environment;
    }
    return cookieName + suffix;
};
/**
 * Sjekker om cookie finnes og om den har verdi
 * @param cookieName navn på cookie
 */
export const hasCookieWithSuffix = (cookieName) => {
    return hasCookie(getSuffix(cookieName));
};
/**
 * Sjekker om bruker er logget inn basert på cookie
 */
export const hasLoggedInCookie = () => {
    return hasCookieWithSuffix(LOGGED_IN_COOKIE_NAME) || hasCookieWithSuffix(SESSION_ID_COOKIE_NAME);
};
/**
 * Setter en cookie med van + verdi
 * @param cookieName navn på cookie
 * @param value cookie verdi
 */
export const setCookie = (name, value = '') => {
    document.cookie = `${name}=${value};path=/;`;
};
/**
 * Sletter en cookie basert på navnet
 * @param cookieName navn på cookie
 */
export const deleteCookie = (name) => {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
};
/**
 * Henter verdien til en cookie basert på navnet
 * @param cookieName navn på cookie
 */
export const getCookieValue = (name) => {
    const cookie = hasCookie(name);
    const cookieWithSuffix = hasCookieWithSuffix(name);
    if (!cookie && !cookieWithSuffix) {
        return undefined;
    }
    const cookieName = cookieWithSuffix ? getSuffix(name) : name;
    return document.cookie
        .split('; ')
        .find(e => e.split('=')[0] === cookieName)
        ?.split('=')[1];
};
/**
 * Returnerer domenenavnet uten subdomene, f.eks. "helsenorge.no" dersom domenet er "www.helsenorge.no" eller "tjenester.helsenorge.no"
 * @returns domenenavn for bruk i cookie
 */
export const getHostnameWithoutSubdomain = () => {
    if (['127.0.0.1', 'localhost'].includes(window.location.hostname)) {
        return window.location.hostname;
    }
    return window.location.hostname.substring(window.location.hostname.indexOf('.') + 1);
};
export var IdentityProvider;
(function (IdentityProvider) {
    IdentityProvider["HnApp"] = "hnapp";
    IdentityProvider["HnAppUngdom"] = "idportenlevel3";
    IdentityProvider["IdPorten"] = "idporten";
    IdentityProvider["IdPortenLevel4"] = "idportenlevel4";
})(IdentityProvider || (IdentityProvider = {}));
/**
 * Henter måten bruker har logget inn på
 * @returns IdentityProvider
 */
export const getIdentityProvider = () => getCookieValue(IDENTITY_PROVIDER_COOKIE_NAME);
/**
 * Parse en string til et objekt med minor/minor/patch-versjon
 * @param input Versjon på semver-format 25.01.00
 * @returns Version-objekt eller undefined dersom versjonen ikke kan parses
 */
export const parseVersion = (input) => {
    if (!/^\d+\.\d+\.\d+$/.test(input))
        return undefined;
    const [major, minor, patch] = input.split('.').map(Number);
    return { major, minor, patch };
};
/**
 * Sjekk om en versjon er lik eller høyere enn påkrevd versjon
 * @param compare Versjon å sammenlikne med
 * @param required Påkrevd versjon
 * @returns true dersom input er lik eller høyere enn base
 */
export const isVersionEqualOrHigher = (compare, required) => {
    if (compare.major > required.major)
        return true;
    if (compare.major < required.major)
        return false;
    if (compare.minor > required.minor)
        return true;
    if (compare.minor < required.minor)
        return false;
    return compare.patch >= required.patch;
};
/**
 * Les app-versjon fra cookie
 * @returns Versjons-info om appen dersom cookie finnes og inneholder gyldig versjonsinfo
 */
export const getAppVersion = () => {
    const value = getCookieValue(APP_VERSION_COOKIE_NAME);
    if (value) {
        return parseVersion(value);
    }
};
/**
 * Sjekk om innlogget bruker har tilstrekkelig ny versjon av appen
 * @param required Versjon å sammenlikne med, på formatet 25.01.00
 * @returns true dersom appen har høyere eller lik versjon som input
 */
export const hasAppVersionOrHigher = (required) => {
    const appVersion = getAppVersion();
    const requiredVersion = parseVersion(required);
    if (appVersion && requiredVersion) {
        return isVersionEqualOrHigher(appVersion, requiredVersion);
    }
    return false;
};
